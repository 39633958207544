<template>
    <widget-container class="widget-iframe-container" name="Slideshow" v-if="widget" :widget="widget" :loading="loading">
        <div class="slideshow" v-if="widget.loaded" :class="['transition-' + widget.config.transition, { 'transition-started': transitionStarted }]">
            <b-img class="slideshow-image current-image" :style="transitionStyle" :src="currentFile.full_path"/>
            <b-img class="slideshow-image next-image" :style="transitionStyle" :src="nextFile.full_path"/>
        </div>
    </widget-container>
</template>

<script>
import {Widget} from "../helpers/Widget.js";

export default {
    name: `widget-slideshow`,
    data() {
        return {
            loading: false,
            currentIndex: 0,
            transitionAnimation: `none`,
            transitionDuration: 10,// secs
            displayDuration: 500,//ms
            transitionStarted: false,
            preloadImage: false,
            preloadDuration: 1000,// duration after which swap images to avoid flickering
            widget: null,
            error: false
        }
    },
    computed: {
        images() {
            return this.widget.config.files;
        },
        currentFile() {
            if (this.preloadImage) {
                return this.nextFile;
            }
            return this.images[this.currentIndex];
        },
        nextFile() {
            return this.images[(this.currentIndex + 1) % this.images.length];
        },
        transitionStyle() {
            return {
                animationDuration: this.transitionDuration + `ms`
            }
        },
        // currentImageStyle() {
        //     let style = this.transitionStyle;
        //     // if (this.preloadImage) {
        //     //     style.opacity = 0;
        //     // }
        //     return style;
        // },
        // nextImageStyle() {
        //     let style = this.transitionStyle;
        //     // if (this.preloadImage) {
        //     //     style.opacity = 1;
        //     // }
        //     return style;
        // }
    },
    async mounted() {
        this.widget = new Widget(`map`, this.$route.params.id);
        await this.widget.load();
        this.loading = false;
        this.transitionAnimation = this.widget.config.transition;
        // Durations are in ms
        this.transitionDuration = this.widget.config.transition_duration;
        this.displayDuration = this.widget.config.display_duration;
        setTimeout(() => {
            this.startTransition();
        }, this.displayDuration)
    },
    methods: {
        startTransition() {
            // console.log(`start transition`);
            if (this.transitionAnimation === `none` || this.transitionDuration <= 0) {
                this.next();
            } else {
                this.transitionStarted = true;
                setTimeout(() => {
                    this.next();
                }, this.transitionDuration);// ms
            }
        },
        next() {
            // console.log(`preloading next`);

            // current image should be at opacity 0 now, so preload the next image in the current to avoid flashing briefly the previous image
            this.preloadImage = true;
            setTimeout(() => {
                // console.log(`next`);
                this.transitionStarted = false;
                this.preloadImage = false;
                this.currentIndex = (this.currentIndex + 1) % this.images.length;
            }, this.preloadDuration);

            setTimeout(() => {
                this.startTransition();
            }, this.displayDuration);
        }
    }
}
</script>

<style lang="scss" scoped>
.slideshow {
    position: relative;
    width: 100%;
    height: 100%;
    background: black;

    .slideshow-image {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        animation-fill-mode: both;
        background: black;
    }

    .next-image {
        opacity: 0;
    }

    &.transition-started {
        &.transition-fade-white,
        &.transition-fade-black {
            .current-image {
                animation-name: current-fade-by-color;
            }
            .next-image {
                animation-name: next-fade-by-color;
            }
        }

        &.transition-fade-in-out {
            .current-image {
                animation-name: current-fade-in-out;
            }
            .next-image {
                animation-name: next-fade-in-out;
            }
        }
    }

    &.transition-fade-white {
        background: white;
    }
}

@keyframes current-fade-by-color {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes next-fade-by-color {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes current-fade-in-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes next-fade-in-out {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
</style>
